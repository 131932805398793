// Here you can add other styles
// Here you can add other styles
@font-face {
    font-family: 'kanitbold';
    src: url('assets/font/kanit-bold-webfont.woff2') format('woff2'),
         url('assets/font/kanit-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  
  @font-face {
    font-family: 'kanitregular';
    src: url('assets/font/kanit-regular-webfont.woff2') format('woff2'),
         url('assets/font/kanit-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  
  body{
    font-family: "kanitregular" !important;  
  }
   
   /* form-control 2 for table */
  .form-control-table {
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      margin-bottom : 0px;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-image: none;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
    
    .form-control-table::-ms-expand {
      background-color: transparent;
      border: 0;
    }
    
    .form-control-table:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: none;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    
    .form-control-table::-webkit-input-placeholder {
      color: #868e96;
      opacity: 1;
    }
    
    .form-control-table:-ms-input-placeholder {
      color: #868e96;
      opacity: 1;
    }
    
    .form-control-table::-ms-input-placeholder {
      color: #868e96;
      opacity: 1;
    }
    
    .form-control-table::placeholder {
      color: #868e96;
      opacity: 1;
    }
    
    .form-control-table:disabled, .form-control-table[readonly] {
      background-color: #e9ecef;
      opacity: 1;
    }

    .main{
      //background-color: whitesmoke !important;
    }